<template>
  <div class="geolocator-modal-container">
    <SfModal
      class="geolocator-modal"
      :persistent="true"
      :visible="isGeoLocatorModalOpen"
      @close="
        () => {
          updateCookie();
          toggleGeoLocatorModal();
        }
      "
    >
      <template #close>
        <CrossIcon />
      </template>
      <template #modal-bar>
        <div />
      </template>
      <div class="geolocator-modal-content">
        <div class="geolocator-modal-body">
          <div class="geolocator-modal-text-top">
            <p>
              {{ $t('You seem to be connected from ')
              }}<b>{{ $t(`${countryLabel}`) }}</b>
            </p>
            <nuxt-img
              v-if="countryFlag"
              :src="countryFlag"
              format="webp"
              width="20px"
              height="13px"
              alt="flag"
              loading="lazy"
            />
          </div>
        </div>
        <div class="geolocator-modal-bottom">
          <CustomButton
            :text="$t('Continue navigating')"
            @click="
              () => {
                updateCookie();
                toggleGeoLocatorModal();
              }
            "
          />
          <CustomButton
            :text="$t('Change country')"
            @click="
              () => {
                updateCookie();
                toggleGeoLocatorModal();
                toggleCountrySidebar();
              }
            "
          />
        </div>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import {
  defineComponent,
  useContext,
  reactive,
  toRefs,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { CustomButton } from '~/components/General/index';
import { CrossIcon } from '~/components/General/Icons';
import { geolocatorCookieName } from '~/enums/cookieNameEnum';

export default defineComponent({
  name: 'GeoLocatorModal',
  components: { SfModal, CrossIcon, CustomButton },
  props: {
    localizedCountry: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const {
      isGeoLocatorModalOpen,
      toggleGeoLocatorModal,
      toggleCountrySidebar,
    } = useUiState();
    const {
      app: { $cookies },
    } = useContext();
    const countryData = reactive({
      countryLabel: props.localizedCountry?.label,
      countryFlag: props.localizedCountry?.flag,
      countryCode: props.localizedCountry?.code,
    });

    const locatorCookieData = ref();
    const updateCookie = () => {
      $cookies.remove(geolocatorCookieName);
      $cookies.set(
        geolocatorCookieName,
        {
          ...locatorCookieData.value,
          interacted: true,
        },
        {
          maxAge: 60 * 60 * 24 * 30,
          path: '/',
          sameSite: 'lax',
        }
      );
    };

    onMounted(() => {
      try {
        locatorCookieData.value = $cookies.get(geolocatorCookieName);
        const pathParts =
          locatorCookieData.value.redirectDestination.split('/');
        localStorage.setItem(
          'vsf-country-selected',
          JSON.stringify({
            value: pathParts[1],
            path: pathParts.slice(2).join('/'),
            label: countryData.countryLabel,
            countryIso: countryData.countryCode.toUpperCase(),
          })
        );
      } catch (error) {
        console.error('GeoLocatorModal ~ onMounted ~ error:', error);
      }
    });

    return {
      isGeoLocatorModalOpen,
      toggleGeoLocatorModal,
      toggleCountrySidebar,
      ...toRefs(countryData),
      updateCookie,
    };
  },
});
</script>

<style lang="scss">
.geolocator-modal-container {
  .geolocator-modal {
    --modal-index: 101;
    --overlay-z-index: 101;
    .sf-modal {
      &__container {
        .sf-modal__content {
          .geolocator-modal {
            &-body {
              margin-top: 1.5rem;
              text-align: center;
              .geolocator-modal-text-top {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
              }
            }
            &-bottom {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              margin: 1rem 1rem 0;
              .geolocator-modal-cta-accept {
                @include font-14x16;
                background-color: var(--c-black);
                color: var(--c-white);
                border: none;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: var(--font-weight-medium);
                width: 100%;
                height: var(--button-height);
                border-radius: var(--border-radius);
              }
            }
          }
        }
      }
    }
  }
}
@include from-landscape-min {
  .geolocator-modal-container {
    .geolocator-modal {
      --modal-width: 50vw;
      --modal-max-height: 25rem;
      p {
        @include font-14x19;
      }

      .sf-modal {
        &__container {
          min-height: 15rem;
          //.geolocator-modal-bar {
          //  display: none;
          //}
          .sf-modal__content {
            .geolocator-modal {
              &-body {
                margin-top: 2rem;
                text-align: center;
              }
              &-bottom {
                flex-direction: row;
                margin: 2.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
}
@include to-portrait-max {
  .geolocator-modal-container {
    .geolocator-modal {
      .sf-modal {
        &__container {
          height: 22rem;
          top: calc(50vh - 11rem);
        }
      }
    }
  }
}
@media ((min-width: 768px) and (max-width: 1023px)) {
  .geolocator-modal-container {
    .geolocator-modal {
      --modal-width: 80vw;
      --modal-left: calc(50% - (80vw / 2));
      .sf-modal {
        &__container {
          top: 6.875rem;
        }
      }
    }
  }
}
</style>
